
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { changeState, logout, mudarMensagens, mudarProcesso } from '../actions/AppActions';
import { connect } from 'react-redux';

import moment from 'moment-timezone';
import SweetAlert from 'react-bootstrap-sweetalert';
import { URL } from '../variables';
import Chat from './Chat';
import axios from 'axios';
import {Buffer} from 'buffer';

class AceiteRegras extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect: false,
            path: '/',

            show_warning: false,
            loading_aceite: false,
            show_error: false
        };
        this.last_date = moment().format('YYYY-MM-DD HH:mm:ss');
        this.canvas = React.createRef();
        this.videoRef = React.createRef();
        this.time_text = '88:88:88';
        this.unmont_ = false;
    }

    incTimer() {
        // var currentMinutes = Math.floor(totalSecs / 60);
        let last_date_new = moment().format('YYYY-MM-DD HH:mm:ss');

        let diff = moment().tz('America/Sao_Paulo').diff(this.last_date, 'seconds');
        this.last_date = last_date_new;

        if (Math.abs(diff) >= 2) {

            this.seconds_diff = this.seconds_diff + (-1 * diff) + 1;

        }

        var totalSecs = moment(this.props.processo.fim, 'YYYY-MM-DD HH:mm:ss').diff(moment().add(this.seconds_diff, 'seconds').format('YYYY-MM-DD HH:mm:ss'), 'seconds');
        var currentHours = Math.floor((totalSecs / 60) / 60);
        var currentMinutes = Math.floor(totalSecs / 60) - currentHours * 60

        var currentSeconds = totalSecs % 60;
        if (currentSeconds <= 9) currentSeconds = "0" + currentSeconds;
        if (currentMinutes <= 9) currentMinutes = "0" + currentMinutes;
        if (currentHours <= 9) currentHours = "0" + currentHours;
        if (document.getElementById('timer') == null) {
            return;
        }


        if (totalSecs <= 0) {
            totalSecs = 0;
            this.time_text = "00:00:00";
            document.getElementById('timer').innerHTML = 'Tempo restante: 00:00:00';

            this.props.get_processo();
        }
        else {
            this.time_text = currentHours + ":" + currentMinutes + ":" + currentSeconds;
            document.getElementById('timer').innerHTML = 'Tempo restante: ' + currentHours + ":" + currentMinutes + ":" + currentSeconds;

            this.timer_timeout = setTimeout(function () {
                this.incTimer()
            }.bind(this), 1000);
        }
    }

    componentDidUpdate(props){
        if (this.props.tempo_atual != null && this.props.tempo_atual != props.tempo_atual) {
            this.seconds_diff = moment(this.props.tempo_atual).diff(moment(), 'seconds');
        }
    }

    componentDidMount() {
        this.seconds_diff = moment(this.props.tempo_atual).diff(moment(), 'seconds');
        if (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') {
            this.videoRef.current.srcObject = this.props.stream;
        }

        this.incTimer();
        if (this.props.processo.simulado == false) {
            this.logs_timeout = setTimeout(() => {
                this.salvar_log();
            }, this.props.processo.save_timer_pre_exam);
            if(this.props.socket != null && this.props.user.accessibility && this.props.user.type_supervisor!='none' && this.props.processo.is_interview==false){
                console.log('montou',this.props.user)
                this.props.changeState({show_call:true});
            }
        }
        else{
            this.lineCheckInterval = setInterval(() => {
                if(this.props.socket != null){
                    this.props.socket.emit("line_check", { aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id,enter_now:true });
                }
            }, 30000);
        }

        this.timer_get_time = setTimeout(() => {
            this.get_time_server();
        }, 30000);

    }

   


    salvar_aceite(foto) {
        fetch(`${URL}api/aceitar_termos`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            body: JSON.stringify({
                processo_id: this.props.processo.id
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else if (resp.message == "Server Error") {
                    this.timeout_aceitar_regras = setTimeout(() => {
                        this.aceitar_regras(foto, true);
                    }, 5000);
                }
                else if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ loading_aceite: false, show_error: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    this.props.aceitar_regras(resp);
                }




            } catch (err) {
                // this.setState({ loading_aceite: true, show_error: true });
                this.timeout_aceitar_regras = setTimeout(() => {
                    this.aceitar_regras(foto, true);
                }, 5000);
                console.log(err);
            }

        })
            .catch((err) => {
                // this.setState({ loading_aceite: true, show_error: true });
                this.timeout_aceitar_regras = setTimeout(() => {
                    this.aceitar_regras(foto, true);
                }, 5000);
                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    aceitar_regras(foto, file_saved = false) {
        if (this.unmont_ == true) {
            return;
        }
        console.log(this.props.processo_resultado);
        this.setState({ loading_aceite: true, show_warning: false });
        if ((this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') && foto != null) {
            if (file_saved == true) {
                this.salvar_aceite(foto);
            }
            else if (this.props.processo_resultado.url_upload_photo != undefined) {
                const type = foto.split(';')[0].split('/')[1]
                const buffer = Buffer.from(foto.replace(/^data:image\/\w+;base64,/, ""), 'base64');

                console.log(buffer);
                axios.put(this.props.processo_resultado.url_upload_photo, buffer, {
                    headers: {
                        "Content-Type": type
                    }
                }).then((resp) => {
                    console.log(resp)
                    this.salvar_aceite(foto);


                }).catch((error) => {
                    console.log(error);
                    //link expirou provavelmente
                    let error_string='error';
                    if (typeof error === 'string' || error instanceof String)
                        error_string=error;
                    else if(error){
                        error_string=JSON.stringify(error)
                    }
                    this.salvar_log('error',error_string)
                    if (error.response) {
                        this.props.get_processo();
                    }
                    else {
                        this.timeout_aceitar_regras = setTimeout(() => {
                            this.aceitar_regras(foto);
                        }, 5000);
                    }

                });
            }
            else {
                this.props.get_processo();
                return;
            }
        }
        else {
            axios.put(this.props.processo_resultado.url_upload_photo, 'teste', {
                headers: {
                    "Content-Type": 'image/jpeg'
                }
            }).then((resp) => {
                console.log(resp)
                this.salvar_aceite(null);
            }).catch((error) => {
                console.log(error);
                //link expirou provavelmente
                let error_string='error';
                if (typeof error === 'string' || error instanceof String)
                    error_string=error;
                else if(error){
                    error_string=JSON.stringify(error)
                }
                this.salvar_log('error',error_string)
                if (error.response) {
                    this.props.get_processo();
                }
                else {
                    this.timeout_aceitar_regras = setTimeout(() => {
                        this.aceitar_regras(null);
                    }, 5000);
                }

            });
            // this.salvar_aceite(null);
        }


    }

    componentWillUnmount() {
        clearTimeout(this.timeout_aceitar_regras);
        clearTimeout(this.logs_timeout);
        clearTimeout(this.timer_timeout);
        this.unmont_ = true;
        clearTimeout(this.timer_get_time);
        clearInterval(this.lineCheckInterval);
    }

    salvar_log(error='aceite',msg='') {
        fetch(`${URL}api/salvar_log?screen=${error}&processo_id=${this.props.processo.id}&processo_name=${this.props.processo.name}&msg=${msg}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    this.seconds_diff = moment(resp.atual).diff(moment(), 'seconds');
                    if (resp.status == false) {
                        this.props.get_processo();
                    }
                    else {
                        if (resp.processo != null && resp.processo.updated_at != this.props.processo.updated_at) {
                            this.props.mudarProcesso(resp.processo);
                        }
                        if (resp.atual != null) {
                            this.seconds_diff = moment(resp.atual).diff(moment(), 'seconds');
                        }
                        if (this.unmont_ == false && this.props.processo.simulado==false && error=='aceite') {
                            this.logs_timeout = setTimeout(() => {
                                this.salvar_log();
                            }, this.props.processo.save_timer_pre_exam);
                        }
                    }
                }
            } catch (err) {
                console.log(err);
                if (this.unmont_ == false && this.props.processo.simulado==false && error=='aceite') {

                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, this.props.processo.save_timer_pre_exam);
                }
            }

        })
            .catch((err) => {
                console.log(err);
                if (this.unmont_ == false && this.props.processo.simulado==false && error=='aceite') {

                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, this.props.processo.save_timer_pre_exam);
                }
            });
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    

    get_time_server() {

        fetch(`${URL}api/get_time_server`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                else {
                    if (resp.atual != null) {
                        this.seconds_diff = moment(resp.atual).diff(moment(), 'seconds');
                    }
                }
                this.timer_get_time = setTimeout(() => {
                    this.get_time_server();
                }, 30000);
            } catch (err) {
                console.log(err);
                this.timer_get_time = setTimeout(() => {
                    this.get_time_server();
                }, 30000);
            }

        })
            .catch((err) => {
                console.log(err);
                this.timer_get_time = setTimeout(() => {
                    this.get_time_server();
                }, 30000);
            });
    }




    render() {

        return (
            <div >
                {/* {<div  style={this.props.processo.type_fiscalization == 'none'?{bottom:0,right:0,top:'unset'}:{}} id='timer'
                    className='timer_exam'>

                </div>} */}
                <hr />
                <p className="m-b-20" style={{ color: 'black', marginBottom: '0.4rem', fontSize: '20px', textAlign: 'center' }}>
                    <b>Regras:</b>
                </p>
                <div style={{ fontSize: '1rem', color: 'black', marginBottom: '0.4rem' }} className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.props.processo.regras }}>
                </div>
                <hr />


                {this.props.connect == false && this.props.socket!=null && <div>
                    <p style={{ fontSize: '1.2rem', color: 'red', textAlign: 'center',textTransform:'uppercase' }}>Sua conexão está instável...</p>
                </div>}

                {this.state.loading_aceite == false && <div className="row">
                    <div className="col-12">
                        <button type="button" id="accept" onClick={this.onAceite.bind(this)} className="btn btn-success btn-lg waves-effect waves-light"
                            style={{ width: '100%' }}>Aceitar regras da avaliação</button>
                    </div>
                </div>}
                {this.state.loading_aceite == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status"  >
                        <span className="sr-only">Loading...</span>
                    </div>
                </div></div>}
                <canvas ref={this.canvas} id="background-canvas" style={{ position: 'absolute', top: '-500px', left: '-500px' }}></canvas>
                <video ref={this.videoRef} muted autoPlay id="background-canvas" style={{ height: '100px', position: 'absolute', top: 200, zIndex: -1 }}></video>
                {this.props.processo.simulado==false && (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') && this.props.processo.chat_type != 'none' && <Chat 
                send_message={(m)=>this.props.send_message(m)}
                />}

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={this.onConfirm.bind(this)}
                    onCancel={this.onCancel.bind(this)}
                    show={this.state.show_warning}
                    confirmBtnText='Aceito'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Aceitando as regras será tirada uma foto sua

                </SweetAlert>
                <SweetAlert
                    danger
                    title={"Erro!"}
                    onConfirm={() => this.setState({ show_error: false })}
                    // onCancel={this.onCancel.bind(this)}
                    show={this.state.show_error}
                    confirmBtnText='Ok'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="info"
                    // cancelBtnBsStyle="success"
                    showCancel={false}
                >
                    Algo deu errado. Por favor, tente novamente
                </SweetAlert>
            </div>

        );
    }

    onConfirm() {
        this.setState({ show_warning: false });
        this.canvas.current.height = this.videoRef.current.videoHeight;
        this.canvas.current.width = this.videoRef.current.videoWidth;
        this.canvas.current.getContext('2d').drawImage(this.videoRef.current, 0, 0);
        this.aceitar_regras(this.canvas.current.toDataURL('image/jpeg', 0.15));
    }

    onCancel() {
        this.setState({ show_warning: false });
    }

    onAceite() {
        if (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') {
            this.setState({ show_warning: true });
        }
        else {
            this.aceitar_regras(null);
        }
    }
}
const mapsStateToProps = (state) => (
    {
        tempo_atual: state.AppReducer.tempo_atual,
        processo: state.AppReducer.processo,
        faculdade: state.AppReducer.faculdade,
        stream: state.AppReducer.stream,
        token: state.AppReducer.token,
        processo_resultado: state.AppReducer.processo_resultado,
        connect: state.AppReducer.connect,
        socket: state.AppReducer.socket,
        mensagens: state.AppReducer.mensagens,
        user: state.AppReducer.user,
        sala: state.AppReducer.sala,

    }
);

export default withRouter(connect(mapsStateToProps, { logout, mudarProcesso,mudarMensagens,changeState })(AceiteRegras));


