
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { logout, changeState, mudarProcesso } from '../actions/AppActions';
import { connect } from 'react-redux';

import moment from 'moment-timezone';
import { URL } from '../variables';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import Redacao from './Redacao';
import { Tab, Tabs } from 'react-bootstrap';

const DecideBorder = (resposta, correta, atual) => {
    console.log(resposta,correta,atual)
    if (atual == resposta && resposta == correta) {
        return '1px solid green';
    }
    else if (atual == resposta) {
        return '1px solid red';
    }
    else if (atual == correta) {
        return '1px solid green';
    }
    else {
        return '';
    }
}

class Finalizada extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect: false,
            path: '/',
            tab: 0,

        };
        this.unmont_ = false;

    }


    componentDidMount() {
        this.props.changeStateParent({ show_face_mesh: false });
        try {

            if (this.props.stream != null) {
                console.log('finalizado stream4')

                this.props.stream.oninactive = function () { }
                this.props.stream.getTracks().map((track) => {
                    track.stop();
                })
            }
        }
        catch (e) {
            console.log(e)
        }
        try {

            if (this.props.stream_screen != null) {
                console.log('finalizado')
                this.props.stream_screen.oninactive = function () { }
                this.props.stream_screen.getVideoTracks()[0].onended = function () { }

                this.props.stream_screen.getTracks().map((track) => {

                    track.stop();
                })
            }
        }
        catch (e) {
            console.log(e)
        }

        this.props.changeState({ stream: null, stream_screen: null });
        // this.props.object_worker.terminate();
        if (this.props.processo.simulado == false) {
            this.logs_timeout = setTimeout(() => {
                this.salvar_log();
            }, this.props.processo.save_timer_pre_exam);
        }
        else {
            this.logs_timeout = setTimeout(() => {
                localStorage.removeItem('token');
                this.props.logout();
            }, 30000);
        }

        this.props.provas.map((prova,id2)=>{
            if(prova.prova.type == 'multipla_escolha' && 
                prova.prova.folha_resposta == true && prova.prova.mirror == false
            ){
                this.download_answers(id2)
            }
        })
    }

    componentWillUnmount() {
        this.unmont_ = true;

        clearTimeout(this.logs_timeout);
    }

    salvar_log() {
        fetch(`${URL}api/salvar_log?screen=finish&processo_id=${this.props.processo.id}&processo_name=${this.props.processo.name}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    if (resp.status == false) {
                        this.props.get_processo();
                    }
                    else {
                        if (resp.processo != null && resp.processo.updated_at != this.props.processo.updated_at) {
                            this.props.mudarProcesso(resp.processo);
                        }
                        if (this.unmont_ == false) {

                            this.logs_timeout = setTimeout(() => {
                                this.salvar_log();
                            }, this.props.processo.save_timer_pre_exam);
                        }
                    }
                }
            } catch (err) {
                console.log(err);

                if (this.unmont_ == false) {

                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, this.props.processo.save_timer_pre_exam);
                }
            }

        })
            .catch((err) => {
                console.log(err);
                if (this.unmont_ == false) {

                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, this.props.processo.save_timer_pre_exam);
                }
            });
    }

    logout() {
        localStorage.removeItem('token');
        // this.setState({redirect:true,path:'/'});
        this.props.logout();
    }

    changeTab(k) {
        console.log(k)
        this.setState({ tab: k })
    }

    mapAlternative(alt){
        if(alt.order==1){
            return 'a)'
        }
        else if(alt.order==2){
            return 'b)'
        }
        else if(alt.order==3){
            return 'c)'
        }
        else if(alt.order==4){
            return 'd)'
        }
        else if(alt.order==5){
            return 'e)'
        }
    }

    async download_answers(id2){
        var doc = new jsPDF('l', 'px', 'a4')
        var width = doc.internal.pageSize.width - 20;
        var height = doc.internal.pageSize.height;
        doc.internal.write(0, "Tw")
        await doc.html('<div style="width:' + width + 'px;padding:10px">' + document.getElementById('resultados_' + id2).innerHTML + '</div>', {
            pagebreak: { mode: 'avoid-all', after: '.avoidThisRow' },
            html2canvas: {
                // insert html2canvas options here, e.g.
                width: doc.internal.pageSize.width,
                allowTaint: false,
                useCORS: true
            },
        });
        doc.save('suas_respostas_' + this.props.processo.name.replace(/\./g, '') + '.pdf');
    }

    render() {
        let index = 0;

        return (
            <div >

                <hr />
                <div className="text-success"
                    style={{ padding: '5px 5px 5px 5px', fontWeight: 'bold', fontSize: '1.4rem', textAlign: 'center' }}>
                    Avaliação finalizada com sucesso
                </div>
                {/* <hr/> */}

                <br />
                <br />
                {(this.props.provas?.length != 0 || this.props.redacoes?.length != 0) && <div>

                    <h5 style={{ color: 'black', fontWeight: 600 }}>Respostas:</h5>
                </div>}
                <Tabs
                    id="controlled-tab-example"
                    activeKey={this.state.tab}
                    onSelect={(k) => {
                        if (this.props.processo.exam_per_time == false) {
                            this.changeTab(k);
                        }
                    }}
                >

                    {/* {this.props.provas.filter((item, id) => item.prova.folha_resposta == true).length != 0 && this.props.provas != null && this.props.provas.length > 0 && this.props.processo_resultado != null && this.props.processo_resultado.gabarito == false && }

                    {this.props.provas.filter((item, id) => item.prova.folha_resposta == true).length != 0 && this.props.provas != null && this.props.provas.length > 0 && this.props.processo_resultado != null && this.props.processo_resultado.gabarito == false && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }}>

                    </div>} */}



                    {/* {this.props.provas.filter((item, id) => item.prova.show_acertos == true).length != 0 && this.props.provas != null && this.props.provas.length > 0 && this.props.processo_resultado != null && this.props.processo_resultado.gabarito == false && <div>
                        <hr />

                        <h5 style={{ color: 'black', fontWeight: 600 }}>Relação de acerto/erros</h5>
                        <p style={{ color: 'black', fontSize: '1rem', marginBottom: '2.2rem' }}>A relação de acertos/erros será exibido apenas uma vez. Após sair ou atualizar a página, não será possível baixá-la novamente.</p>
                    </div>} */}
                    {/* {this.props.provas.filter((item, id) => item.prova.show_acertos == true).length != 0 && this.props.provas != null && this.props.provas.length > 0 && this.props.processo_resultado != null && this.props.processo_resultado.gabarito == false && <div id="resultados_2"> */}


                    {this.props.provas.map((prova, id2) => {
                        if (prova.prova.type == 'multipla_escolha') {
                            let number_questao = 0;
                            if (prova.prova.show_acertos == true) {
                                index += 1;

                                return (
                                    <Tab tabClassName={'tab'} key={id2} eventKey={index - 1} title={prova.prova.name}>
                                        <br />
                                        <br />
                                        <div className="row d-flex justify-content-center mr-2" style={{ float: 'right' }}>

                                            <img src={this.props.faculdade.logo} alt="" height='60' style={{ height: 40, objectFit: 'contain' }} />
                                        </div>
                                        <h5 style={{ textAlign: 'center', fontWeight: 600 }}>
                                            Relação de acerto/erros</h5>
                                        <p style={{ textAlign: 'justify', marginBottom: 0 }}>Nome do Candidato: {this.props.user.name}</p>
                                        {this.props.user.numero_inscricao != null && <p style={{ textAlign: 'justify', marginBottom: 0 }}>Número de Inscrição: {this.props.user.numero_inscricao}</p>}
                                        <div className='mt-2 mb-2' style={{ marginLeft: 0, marginRight: 0 }}>
                                            <div style={{ color: 'black', width: 'auto' }}>
                                                <i style={{ color: 'green' }} className='mdi mdi-check'></i>: Acertou
                                            </div>
                                            <div style={{ color: 'black', width: 'auto' }}>

                                                <i style={{ color: 'red' }} className='mdi mdi-close'></i>: Errou
                                            </div>

                                            <div style={{ color: 'black', width: 'auto' }}>

                                                <span>--  : Sem resposta</span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div id={"resultados_2_" + index}>
                                            <div key={id2} className="row">

                                                <div className="col-12">
                                                    <p style={{ letterSpacing: 0.2, textAlign: 'justify', marginBottom: 0, whiteSpace: 'pre-line' }}><b>Prova: </b> {prova.prova.name}</p>
                                                    <p style={{ letterSpacing: 0.2, textAlign: 'justify', marginBottom: 0, whiteSpace: 'pre-line' }}><b>Resultado: </b> {Object.values(prova.corretas).filter((item, id) => item == true).length}/{prova.questoes.map((item, id) => {
                                                        if (item.type_questao == 'conjunto') {
                                                            return item.questoes.length;
                                                        }
                                                        else {
                                                            return 1;
                                                        }
                                                    }).reduce((a, b) => a + b, 0)}</p>

                                                    <br />
                                                    <div className="row">

                                                        {prova.questoes.map((item, id) => {
                                                            if (item.type_questao == 'simples' || item.type_questao == 'dissertativa') {
                                                                number_questao = number_questao + 1;
                                                            }
                                                            if (item.type_questao == 'simples') {

                                                                return (
                                                                    <div key={id} style={{ marginTop: '10px', paddingLeft: 15, paddingRight: 15, width: 'auto' }}>

                                                                        <a  >
                                                                            {(prova.respostas[item.id] == undefined) &&
                                                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 65 }}>
                                                                                    <b>{number_questao})</b>
                                                                                    <div style={{ border: '1px solid grey', width: '30px', height: '30px', textAlign: 'center', marginLeft: '0.5rem', justifyContent: 'center', alignItems: 'center', display: 'flex', color: 'black' }}> - </div>
                                                                                </div>}
                                                                            {prova.respostas[item.id] != undefined && <div style={{ display: 'flex', width: 65, justifyContent: 'space-between', alignItems: 'center' }}><b>{number_questao})</b>
                                                                                <div style={{ border: '1px solid grey', width: '30px', height: '30px', textAlign: 'center', marginLeft: '0.5rem', fontWeight: 'bold', color: 'black', justifyContent: 'center', alignItems: 'center', display: 'flex' }}> {item.alternativas.map((item2, id2) => {
                                                                                    if (prova.respostas[item.id] == item2.id) {
                                                                                        return prova.corretas[item.id] == true ? <i key={id2} style={{ color: 'green' }} className='mdi mdi-check'></i> : <i key={id2} style={{ color: 'red' }} className='mdi mdi-close'></i>;
                                                                                    }
                                                                                })} </div></div>}

                                                                        </a>
                                                                    </div>
                                                                )
                                                            }
                                                            else if (item.type_questao == 'conjunto') {
                                                                return (
                                                                    item.questoes.map((item3, id3) => {
                                                                        number_questao = number_questao + 1;
                                                                        return (
                                                                            <div key={id3} style={{ marginTop: '10px', paddingLeft: 15, paddingRight: 15, width: 'auto' }}>

                                                                                <a  >
                                                                                    {(prova.respostas[item3.id] == undefined) &&
                                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 65 }}>
                                                                                            <b>{number_questao})</b>
                                                                                            <div style={{ border: '1px solid grey', width: '30px', height: '30px', textAlign: 'center', marginLeft: '0.5rem', justifyContent: 'center', alignItems: 'center', display: 'flex', color: 'black' }}> - </div>
                                                                                        </div>}
                                                                                    {prova.respostas[item3.id] != undefined && <div style={{ display: 'flex', width: 65, justifyContent: 'space-between', alignItems: 'center' }}><b>{number_questao})</b>
                                                                                        <div style={{ border: '1px solid grey', width: '30px', height: '30px', textAlign: 'center', marginLeft: '0.5rem', fontWeight: 'bold', color: 'black', justifyContent: 'center', alignItems: 'center', display: 'flex' }}> {item3.alternativas.map((item2, id2) => {
                                                                                            if (prova.respostas[item3.id] == item2.id) {
                                                                                                return prova.corretas[item.id] == true ? <i key={id2} style={{ color: 'green' }} className='mdi mdi-check'></i> : <i key={id2} style={{ color: 'red' }} className='mdi mdi-close'></i>;
                                                                                            }
                                                                                        })} </div></div>}

                                                                                </a>
                                                                            </div>
                                                                        );
                                                                    })
                                                                )
                                                            }


                                                        })}



                                                    </div>
                                                    <hr />
                                                </div>


                                            </div >
                                        </div>
                                    </Tab>
                                )
                            }
                            else if (prova.prova.type == 'multipla_escolha') {
                                let number_questao = 0;
                                index += 1;

                                if (prova.prova.folha_resposta == true && prova.prova.mirror == false) {
                                    return (
                                        <Tab tabClassName={'tab'} key={id2} eventKey={index - 1} title={prova.prova.name}>
                                            <br />
                                            <br />

                                            <div id={"resultados_" + id2}>
                                                <div className="row d-flex justify-content-center mr-2" style={{ float: 'right' }}>

                                                    <img src={this.props.faculdade.logo} alt="" height='60' style={{ height: 40, objectFit: 'contain' }} />
                                                </div>
                                                <h5 style={{ textAlign: 'center', fontWeight: 600 }}>
                                                    Folha com suas respostas</h5>
                                                <p style={{ textAlign: 'justify', marginBottom: 0 }}>Nome do Candidato: {this.props.user.name}</p>
                                                {this.props.user.numero_inscricao != null && <p style={{ textAlign: 'justify', marginBottom: 0 }}>Número de Inscrição: {this.props.user.numero_inscricao}</p>}

                                                {this.props.provas.map((prova, id2) => {

                                                })}
                                                <div key={id2} className="row">

                                                    <div className="col-12">
                                                        <p style={{ letterSpacing: 0.2, textAlign: 'justify', marginBottom: 0, whiteSpace: 'pre-line' }}><b>Prova: </b> {prova.prova.name}</p>
                                                        <br />
                                                        <div className="row">

                                                            {prova.questoes.map((item, id) => {
                                                                if (item.type_questao == 'simples' || item.type_questao == 'dissertativa') {
                                                                    number_questao = number_questao + 1;
                                                                }
                                                                if (item.type_questao == 'simples') {

                                                                    return (
                                                                        <div key={id} style={{ marginTop: '10px', paddingLeft: 15, paddingRight: 15, width: 'auto' }}>

                                                                            <a  >
                                                                                {(prova.respostas[item.id] == undefined) &&
                                                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 65 }}>
                                                                                        <b>{number_questao})</b>
                                                                                        <div style={{ border: '1px solid grey', width: '30px', height: '30px', textAlign: 'center', marginLeft: '0.5rem', justifyContent: 'center', alignItems: 'center', display: 'flex' }}> - </div>
                                                                                    </div>}
                                                                                {prova.respostas[item.id] != undefined && <div style={{ display: 'flex', width: 65, justifyContent: 'space-between', alignItems: 'center' }}><b>{number_questao})</b>
                                                                                    <div style={{ border: '1px solid grey', width: '30px', height: '30px', textAlign: 'center', marginLeft: '0.5rem', fontWeight: 'bold', color: 'black', justifyContent: 'center', alignItems: 'center', display: 'flex' }}> {item.alternativas.map((item2, id2) => {
                                                                                        if (prova.respostas[item.id] == item2.id) {
                                                                                            return ((id2 + 1) == 1 ? 'a)' : (id2 + 1) == 2 ? 'b)' : (id2 + 1) == 3 ? 'c)' : (id2 + 1) == 4 ? 'd)' : (id2 + 1) == 5 ? 'e)' : '');
                                                                                        }
                                                                                    })} </div></div>}

                                                                            </a>
                                                                        </div>
                                                                    )
                                                                }
                                                                else if (item.type_questao == 'conjunto') {
                                                                    return (
                                                                        item.questoes.map((item3, id3) => {
                                                                            number_questao = number_questao + 1;
                                                                            return (
                                                                                <div key={id3} style={{ marginTop: '10px', paddingLeft: 15, paddingRight: 15, width: 'auto' }}>

                                                                                    <a  >
                                                                                        {(prova.respostas[item3.id] == undefined) &&
                                                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 65 }}>
                                                                                                <b>{number_questao})</b>
                                                                                                <div style={{ border: '1px solid grey', width: '30px', height: '30px', textAlign: 'center', marginLeft: '0.5rem', justifyContent: 'center', alignItems: 'center', display: 'flex' }}> - </div>
                                                                                            </div>}
                                                                                        {prova.respostas[item3.id] != undefined && <div style={{ display: 'flex', width: 65, justifyContent: 'space-between', alignItems: 'center' }}><b>{number_questao})</b>
                                                                                            <div style={{ border: '1px solid grey', width: '30px', height: '30px', textAlign: 'center', marginLeft: '0.5rem', fontWeight: 'bold', color: 'black', justifyContent: 'center', alignItems: 'center', display: 'flex' }}> {item3.alternativas.map((item2, id2) => {
                                                                                                if (prova.respostas[item3.id] == item2.id) {
                                                                                                    return ((id2 + 1) == 1 ? 'a)' : (id2 + 1) == 2 ? 'b)' : (id2 + 1) == 3 ? 'c)' : (id2 + 1) == 4 ? 'd)' : (id2 + 1) == 5 ? 'e)' : '');
                                                                                                }
                                                                                            })} </div></div>}

                                                                                    </a>
                                                                                </div>
                                                                            );
                                                                        })
                                                                    )
                                                                }


                                                            })}



                                                        </div>
                                                        <hr />
                                                    </div>


                                                </div >
                                            </div>

                                            <button className="btn btn-info btn-lg" onClick={async () => {
                                                this.download_answers(id2);

                                            }} style={{ width: '100%' }}><i
                                                className="mdi mdi-download" style={{ color: 'white' }}></i> Baixar folha com suas respostas</button>

                                        </Tab>

                                    )
                                }
                                else if (prova.prova.folha_resposta == true && prova.prova.mirror == true) {
                                    console.log('aqui')
                                    console.log(prova)
                                    return (
                                        <Tab tabClassName={'tab'} key={id2} eventKey={index - 1} title={prova.prova.name}>
                                        <div key={id2}>
                                            {prova.questoes != null && prova.questoes.map((item, id3) => {
                                                console.log(item)
                                                if (item.type_questao == 'simples' || item.type_questao == 'dissertativa') {
                                                    number_questao = number_questao + 1;
                                                }
                                                return (
                                                    <div key={id3}>
                                                        <hr />

                                                        <h6 className="mt-0 header-title" style={{ textAlign: 'right', marginBottom: 10, fontSize: 15 }}>
                                                            {item.categoria.name}</h6>
                                                        {item.type_questao == 'dissertativa' && <div className="row" style={{ fontWeight: 'normal', margin: 0, margin: 0 }}>
                                                            <div className="col-2 col-sm-1">
                                                                <h5 style={{ textAlign: 'justify', color: 'black', marginTop: 0 }}>
                                                                    <b>{number_questao})</b></h5>
                                                            </div>
                                                            <div className="col-10 col-sm-11">


                                                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.name }}>

                                                                </div>
                                                                {<>
                                                                    <p><b>Resposta: </b></p>
                                                                    <p style={{ whiteSpace: 'pre-line', marginBottom: 0 }}>
                                                                        {prova.respostas[item.id] != undefined ? prova.respostas[item.id] : '-'}
                                                                    </p>
                                                                </>}

                                                            </div>
                                                        </div>}
                                                        {/* {!!Form::open(['action' =>
['ProcessoUserController@responder_questao'],'data-toggle'=>'validator','files' =>
false,'method'=>'post','id'=>$data['prova']->id.'_'.$questao->id]) !!} */}
                                                        {console.log('aquiiiiiiii')}

                                                        {item.type_questao == 'simples' && <div className="row" style={{ fontWeight: 'normal', margin: 0 }}>
                                                            <div className="col-2 col-sm-1">
                                                                <h5 style={{ textAlign: 'justify', color: 'black', marginTop: 0 }}>
                                                                    <b>{number_questao})</b></h5>
                                                            </div>
                                                            <div className="col-10 col-sm-11">


                                                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.name }}>

                                                                </div>
                                                                <p><b>Alternativas:</b></p>
                                                                <p><b>Correta: </b>{this.mapAlternative(item.alternativas.filter((item5)=>item5.id==prova.corretas[item.id])[0])}</p>
                                                                {<>
                                                                    <p><b>Respondida: </b>{prova.respostas[item.id] != undefined ? this.mapAlternative(item.alternativas.filter((item5)=>item5.id==prova.respostas[item.id])[0]) : '-'}</p>
                                                                </>}

                                                                <div style={{ display: 'flex', marginTop: 10, border: DecideBorder(prova.respostas[item.id] != undefined ? prova.respostas[item.id] : '-', prova.corretas[item.id],item.alternativas[0].id ) }}>
                                                                    <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                                        <p style={{ color: 'black', fontWeight: 'normal' }}>a)</p>
                                                                    </div>
                                                                    <div style={{ width: '90%' }}>

                                                                        <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.alternativas[0].name }}>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                {item.alternativas[1] != undefined && <div style={{ display: 'flex', marginTop: 10, border: DecideBorder(prova.respostas[item.id] != undefined ? prova.respostas[item.id] : '-',  prova.corretas[item.id],item.alternativas[1].id ) }}>
                                                                    <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                                        <p style={{ color: 'black', fontWeight: 'normal' }}>b)</p>
                                                                    </div>
                                                                    <div style={{ width: '90%' }}>

                                                                        <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.alternativas[1].name }}>

                                                                        </div>
                                                                    </div>

                                                                </div>}
                                                                {item.alternativas[2] != undefined && <div style={{ display: 'flex', marginTop: 10, border: DecideBorder(prova.respostas[item.id] != undefined ? prova.respostas[item.id] : '-',  prova.corretas[item.id],item.alternativas[2].id ) }}>
                                                                    <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                                        <p style={{ color: 'black', fontWeight: 'normal' }}>c)</p>
                                                                    </div>
                                                                    <div style={{ width: '90%' }}>

                                                                        <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.alternativas[2].name }}>

                                                                        </div>
                                                                    </div>

                                                                </div>}
                                                                {item.alternativas[3] != undefined && <div style={{ display: 'flex', marginTop: 10, border: DecideBorder(prova.respostas[item.id] != undefined ? prova.respostas[item.id] : '-',  prova.corretas[item.id],item.alternativas[3].id ) }}>
                                                                    <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                                        <p style={{ color: 'black', fontWeight: 'normal' }}>d)</p>
                                                                    </div>
                                                                    <div style={{ width: '90%' }}>

                                                                        <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.alternativas[3].name }}>

                                                                        </div>
                                                                    </div>

                                                                </div>}

                                                                {item.alternativas[4] != undefined && <div style={{ display: 'flex', marginTop: 10, border: DecideBorder(prova.respostas[item.id] != undefined ? prova.respostas[item.id] : '-',  prova.corretas[item.id],item.alternativas[4].id ) }}>
                                                                    <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                                        <p style={{ color: 'black', fontWeight: 'normal' }}>e)</p>
                                                                    </div>
                                                                    <div style={{ width: '90%' }}>

                                                                        <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.alternativas[4].name }}>

                                                                        </div>
                                                                    </div>

                                                                </div>}
                                                            </div>
                                                        </div>}





                                                        {item.type_questao == 'conjunto' && <div className="row" style={{ fontWeight: 'normal', margin: 0 }}>

                                                            <div className="col-12 col-sm-12">


                                                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.name }}>

                                                                </div>
                                                                {item.questoes.map((item2, id2) => {
                                                                    number_questao = number_questao + 1;

                                                                    return (<div key={id2} className="row" style={{ fontWeight: 'normal', margin: 0 }}>
                                                                        <div className="col-2 col-sm-1">
                                                                            <h5 style={{ textAlign: 'justify', color: 'black', marginTop: 0 }}>
                                                                                <b>{number_questao})</b></h5>
                                                                        </div>
                                                                        <div className="col-10 col-sm-11">


                                                                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.name }}>

                                                                            </div>
                                                                            <p><b>Alternativas:</b></p>
                                                                            {/* <p><b>Correta: </b>{item2.correta}</p> */}
                                                                            <p><b>Correta: </b>{this.mapAlternative(item2.alternativas.filter((item5)=>item5.id==prova.corretas[item2.id])[0])}</p>

                                                                            {<>
                                                                                <p><b>Respondida: </b>{prova.respostas[item2.id] != undefined ? this.mapAlternative(item.alternativas.filter((item5)=>item5.id==prova.respostas[item2.id])[0]) : '-'}</p>
                                                                                {/* <p><b>Respondida: </b>{prova.respostas[item2.id] != undefined ? prova.respostas[item2.id] : '-'}</p> */}
                                                                            </>}

                                                                            <div style={{ display: 'flex', marginTop: 10, border: DecideBorder(prova.respostas[item2.id] != undefined ? prova.respostas[item2.id] : '-',  prova.corretas[item2.id],item2.alternativas[0].id )}}>
                                                                                <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                                                    <p style={{ color: 'black', fontWeight: 'normal' }}>a)</p>
                                                                                </div>
                                                                                <div style={{ width: '90%' }}>

                                                                                    <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternativas[0].name }}>

                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            {item2.alternativas[1] != undefined && <div style={{ display: 'flex', marginTop: 10, border: DecideBorder(prova.respostas[item2.id] != undefined ? prova.respostas[item2.id] : '-',  prova.corretas[item2.id],item2.alternativas[1].id ) }}>
                                                                                <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                                                    <p style={{ color: 'black', fontWeight: 'normal' }}>b)</p>
                                                                                </div>
                                                                                <div style={{ width: '90%' }}>

                                                                                    <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternativas[1].name }}>

                                                                                    </div>
                                                                                </div>

                                                                            </div>}
                                                                            {item2.alternativas[2] != undefined && <div style={{ display: 'flex', marginTop: 10, border: DecideBorder(prova.respostas[item2.id] != undefined ? prova.respostas[item2.id] : '-',  prova.corretas[item2.id],item2.alternativas[2].id ) }}>
                                                                                <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                                                    <p style={{ color: 'black', fontWeight: 'normal' }}>c)</p>
                                                                                </div>
                                                                                <div style={{ width: '90%' }}>

                                                                                    <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternativas[2].name }}>

                                                                                    </div>
                                                                                </div>

                                                                            </div>}
                                                                            {item2.alternativas[3] != undefined && <div style={{ display: 'flex', marginTop: 10, border: DecideBorder(prova.respostas[item2.id] != undefined ? prova.respostas[item2.id] : '-',  prova.corretas[item2.id],item2.alternativas[3].id ) }}>
                                                                                <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                                                    <p style={{ color: 'black', fontWeight: 'normal' }}>d)</p>
                                                                                </div>
                                                                                <div style={{ width: '90%' }}>

                                                                                    <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternativas[3].name }}>

                                                                                    </div>
                                                                                </div>

                                                                            </div>}

                                                                            {item2.alternativas[4] != undefined && <div style={{ display: 'flex', marginTop: 10, border: DecideBorder(prova.respostas[item2.id] != undefined ? prova.respostas[item2.id] : '-',  prova.corretas[item2.id],item2.alternativas[4].id ) }}>
                                                                                <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                                                    <p style={{ color: 'black', fontWeight: 'normal' }}>e)</p>
                                                                                </div>
                                                                                <div style={{ width: '90%' }}>

                                                                                    <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item2.alternativas[4].name }}>

                                                                                    </div>
                                                                                </div>

                                                                            </div>}
                                                                        </div>
                                                                    </div>)
                                                                })}

                                                            </div>
                                                        </div>}
                                                    </div>

                                                )
                                            })}
                                        </div>
                                        </Tab>
                                    )
                                }
                            }
                        }
                    })}
                    {/* </div>} */}

                    {this.props.redacoes?.map((item, id2) => {
                        index += 1;

                        return (
                            <Tab tabClassName={'tab'} key={id2} eventKey={index - 1}
                                title={item.redacao.name}>
                                <br />
                                <br />
                                <Redacao
                                    show_answer={true}
                                    finishExam={() => { }}

                                    changeTab={(k) => {
                                        // this.changeTab(k);
                                    }}
                                    indice={id2} index={id2} />
                            </Tab>
                        );

                    })}
                </Tabs>



                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }}>
                    <button className="btn btn-success btn-lg" onClick={this.logout.bind(this)} style={{ width: '100%' }}><i
                        className="mdi mdi-power" style={{ color: 'white' }}></i> Clique aqui para Sair</button>

                </div>

            </div>

        );
    }
}
const mapsStateToProps = (state) => (
    {
        tempo_atual: state.AppReducer.tempo_atual,
        processo: state.AppReducer.processo,
        token: state.AppReducer.token,
        user: state.AppReducer.user,
        stream: state.AppReducer.stream,
        stream_screen: state.AppReducer.stream_screen,


        // object_worker: state.AppReducer.object_worker,
        provas: state.AppReducer.provas,
        redacoes: state.AppReducer.redacoes,

        faculdade: state.AppReducer.faculdade,
        processo_resultado: state.AppReducer.processo_resultado,



    }
);

export default withRouter(connect(mapsStateToProps, { logout, changeState, mudarProcesso })(Finalizada));


